
import { Button } from 'element-ui';
import OrderProductCard from './OrderProductCard.vue';
import { OrderHelper } from '@/helpers/OrderHelper';
import { InfoIcon } from 'vue-feather-icons';
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsRetourned: [],
      step: 1,
    };
  },
  computed: {
    currencySign() {
      return this.$localisation.appCurrency.CurrencySign;
    },
    idOrder() {
      return this.order.IdOrder;
    },
    addressDelivery() {
      return this.order.AddressDelivery;
    },
    addressInvoice() {
      return this.order.AddressInvoice;
    },
    dateAdd() {
      return this.order.DateAdd;
    },
    carrierType() {
      return this.order.CarrierType;
    },
    orderQuantity() {
      return this.order.Quantity;
    },
    total() {
      return this.order.TotalPaidTaxIncl;
    },
    products() {
      return this.order.Products;
    },
    showAlertReturnCost() {
      return this.itemsRetourned.some(
        (item) => item?.returnForm?.reasonTypeValue === 0
      );
    },
  },
  methods: {
    onOrderProductCardSelected($event) {
      const { product, returnForm, checked } = $event;

      if (checked) {
        this.itemsRetourned.push({
          product,
          returnForm,
        });
      } else {
        const index = this.itemsRetourned.findIndex(
          (item) => item.product.IdProduct === product.IdProduct
        );

        this.itemsRetourned.splice(index, 1);
      }
    },
    next() {
      let itemHasErrors = null;
      this.itemsRetourned.map((item) => {
        item.returnForm.checkIsValid();

        if (!item.returnForm.valid.state) {
          itemHasErrors = item;
          return;
        }
      });

      if (itemHasErrors === null && this.itemsRetourned.length > 0) {
        this.step = 2;
        if (process.client) {
          window.scrollTo({
            top: this.$refs['orderTop'].offsetTop - 100,
            behavior: 'smooth',
          });
        }
      }
    },
    async onSubmit() {
      const orderHelper = new OrderHelper(
        this.$http,
        this.$store,
        this.$i18n.locale
      );
      try {
        const products = this.itemsRetourned.map((item) => {
          return {
            IdProduct: item.product.IdProduct,
            IdProductAttribute:
              item.product?.Variant?.Combination?.IdProductAttribute,
            Quantity: item.product.Quantity,
            IdOrderDetail: item.product.IdOrderDetail,
            OrderReturnDetailType: item.returnForm.reasonTypeValue,
            OrderReturnReasonCodeValue:
              item.returnForm.resonSelected.OrderReturnReasonCodeValue,
            OrderReturnNewIdProductAttribute: item.returnForm
              .productAttributeSelected
              ? item.returnForm.productAttributeSelected.IdProductAttribute
              : '',
          };
        });

        const productsObject = {};

        productsObject.Products = products;

        

        const response = await orderHelper.addReturnProducts({
          idOrder: this.order.IdOrder,
          productsObject,
          languageIsoCode: this.$i18n.locale,
        });
        if (response.status === 200) {
          this.$router.push(
            this.localePath(
              `/account/return/${response.data.IdOrderReturn}/confirmed`
            )
          );
        }
      } catch (error) {}
    },
  },
  activated() {
    this.step = 1;
  },
  components: { Button, OrderProductCard, InfoIcon },
};
