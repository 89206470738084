
import CustomerService from '@/services/CustomerService';
export default {
  middleware: 'auth',
  props: {
    redirectToLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
      account: {
        firstName: '',
        lastName: '',
        email: '',
        personalID: '',
      },
    };
  },
  methods: {
    hideRightBar() {
      this.$store.commit('shared/menu/toggle', {
        tabActive: 1,
        open: false,
      });
    },
    decodeJwtResponse(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    },
    handleCredentialResponse(response) {
      const user = this.decodeJwtResponse(response.credential);

      let name = user.name.split(' ');
      this.account.personalID = user.sub;
      this.account.email = user.email;
      this.account.firstName = name[0];
      this.account.lastName = name.length > 1 ? name[1] : name[0];

      this.login();
      this.$store.commit('shared/menu/toggle', {
        tabActive: 1,
        open: false,
      });
    },
    async login() {
      this.customerService
        .loginWithGoogle({
          googleCustomerId: this.account.personalID,
          email: this.account.email,
          firstName: this.account.firstName,
          lastName: this.account.lastName,
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        })
        .then((data) => {
          this.hideRightBar();
          this.$gtag.event('login', { method: 'Google' });
          this.rdirectTo();
        })
        .catch((err) => {});
    },
    rdirectTo() {
      let redirectTo = { name: 'account' };

      if (this.redirectToLink) {
        redirectTo = this.redirectToLink;
      } else {
        if (this.$route.query.redirect) {
          if (this.$route.query.redirect === 'loyalty-program') {
            redirectTo = `/account/loyalty/membership`;
          } else {
            redirectTo = '/cart/delivery';
          }
        }
      }


      this.$router.push({
        path: this.localePath(redirectTo),
      });
    },
  },
  mounted() {
    const $this = this;
    if (!this.$auth.loggedIn) {
      try {
        google.accounts.id.initialize({
          client_id: process.env.GOOGLE_CLIENT_ID,
          callback: $this.handleCredentialResponse,
        });
        google.accounts.id.renderButton(
          $this.$refs.gAuthBtn,
          {
            theme: 'outline',
            size: 'large',
            type: 'icon',
          } // customization attributes
        );
        google.accounts.id.prompt();
      } catch (error) {}
    }
  },
};
