
import { InputNumber, Select, Option } from 'element-ui';
import { XIcon, InfoIcon } from 'vue-feather-icons';

let timerUpdateQuantity;

export default {
  props: {
    product: {
      required: true,
      type: Object,
    },
    size: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    imgHeight: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabledEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantitSelected: 1,
      sizeSelected: this.size,
    };
  },

  mounted() {},

  computed: {
    getProductImgs() {
      try {
        return this.product.Media.Images.product_small;
      } catch (error) {}
      return [];
    },
    productImageThumbnailUrl() {
      try {
        return this.getProductImgs[0].Src;
      } catch (error) {}
      return require(`~/assets/img/default-img.jpg`);
    },

    productTitle() {
      if (this.product != null) {
        try {
          return this.product.Description.Title;
        } catch (error) {}
      }
      return '';
    },
    availableSizes() {
      return this.product.AvailableSizes;
    },
    maxQuantiy() {
      if (this.itemSelected) {
        if (Number(this.itemSelected.Quantity) < this.quantitSelected) {
          this.quantitSelected = Number(this.itemSelected.Quantity);
        }
        if (this.itemSelected) {
          return Number(this.itemSelected.Quantity);
        }
      }
      return 0;
    },

    hasPromotion() {
      let price = this.product.Price;
      return price.PromotionalPrice;
    },

    promotionPercentage() {
      return this.product?.Price?.PromotionalPrice?.PriceRuleName;
    },

    productPricePromotion() {
      let price = this.product.Price;
      return price?.PromotionalPrice?.PriceTaxIncl;
    },
    productPrice() {
      let price = this.product?.Price;

      return price?.RegularPrice?.PriceTaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    urlCategory() {
      try {
        const seo = this.product.Category.Main.SEO;
        if (seo) {
          const mainCategory = seo.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode.toLowerCase();
          });
          if (!mainCategory) {
            return 'product';
          }
          if (mainCategory.URL) {
            return mainCategory.URL;
          }
        }
      } catch (error) {}
      return 'product';
    },

    urlProduct() {
      try {
        const seo = this.product.SEO;
        if (seo) {
          const productSeo = seo.find((item) => {
            return this.$i18n.locale === item.LanguageIsoCode.toLowerCase();
          });
          if (!productSeo) {
            return this.product.IdProduct;
          }
          if (productSeo.URL) {
            return productSeo.URL;
          }
        }
      } catch (error) {}
      return this.product.IdProduct;
    },

    link() {
      try {
        if (this.product.SEO) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      } catch (error) {}

      return '#';
    },
  },
  methods: {},

  components: { InputNumber, XIcon, InfoIcon, Select, Option },
};
