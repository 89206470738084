
import {
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon,
  TwitterIcon,
} from 'vue-feather-icons';
import FotterLinks from './FotterLinks.vue';
import NewsLetter from './NewsLetter.vue';

export default {
  data() {
    return {
      isDesktop: true,
    };
  },
  components: {
    InstagramIcon,
    FacebookIcon,
    YoutubeIcon,
    TwitterIcon,
    FotterLinks,
    NewsLetter,
  },
  mounted() {
    this.onResize();
  },
  methods: {
    onResize() {
      this.isDesktop = window.innerWidth > 1023.98;
      window.addEventListener('resize', (e) => {
        this.isDesktop = window.innerWidth > 1023.98 ? true : false;
      });
    },
    openLocalisationDialog() {
      this.$store.commit('shop/localisation/toggleDialog', true);
    },
  },
  computed: {
    getIsDesktop() {
      return this.isDesktop;
    },
    year() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      return currentYear;
    },
  },
};
