
import { Button } from 'element-ui';
import OrderProductCard from './OrderProductCard.vue';
import { InfoIcon } from 'vue-feather-icons';
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    currencySign() {
      return this.$localisation.appCurrency.CurrencySign;
    },
    idOrder() {
      return this.order.IdOrder;
    },
    addressDelivery() {
      return this.order?.Shipping?.Address;
    },
    addressInvoice() {
      return this.order?.Invoice?.Address;
    },
    dateAdd() {
      return this.order?.DateAdd;
    },
    carrierType() {
      return (
        this.order?.Carrier?.Name + ' - ' + this.order?.Carrier?.Title || ''
      );
    },
    paimentType() {
      return this.order?.Payment?.Name;
    },
    orderQuantity() {
      return this.order?.TotalProducts;
    },
    total() {
      try {
        return Number(this.order?.Total?.Paid?.TaxExcl);
      } catch (error) {}

      return 0;
    },
    products() {
      return this.order.Products;
    },
    totalTTC() {
      try {
        return Number(this.order?.Total?.Paid?.TaxIncl);
      } catch (error) {}
      return 0;
    },
    totalShippingHT() {
      return this.order?.Total?.Shipping?.TaxIncl;
    },
  },
  components: { Button, OrderProductCard, InfoIcon },
};
