import { render, staticRenderFns } from "./_product.vue?vue&type=template&id=376efa7d"
import script from "./_product.vue?vue&type=script&lang=js"
export * from "./_product.vue?vue&type=script&lang=js"
import style0 from "./_product.vue?vue&type=style&index=0&id=376efa7d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WishListBtn: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/WishListBtn.vue').default,Breadcrumb: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/Breadcrumb.vue').default,SizeGuide: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/SizeGuide.vue').default,BookingDialog2: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/BookingDialog2/index.vue').default,Reasurances: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/Reasurances.vue').default,BlockSlider: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/BlockSlider.vue').default,CategorySlider: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/CategorySlider.vue').default})
