
import { ChevronRightIcon } from 'vue-feather-icons';
export default {
  props: {
    lookbookProduct: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nbrCol: 6,
      nbrRow: 6,
      hovered: false,
    };
  },
  computed: {
    // link() {
    //   if (this.product.Title) {
    //     return this.localePath(
    //       `/product/${this.product.Title.replace("/", "-").replace("#", "-")}-${this.lookbookProduct.product_url
    //       }`
    //     );
    //   }
    //   return this.localePath("/");
    // },
    hasPromotion() {
      if (this.product?.Price?.PromotionalPrice) {
        return this.product?.Price?.PromotionalPrice;
      }
      return false;
    },
    regularPrice() {
      return this.product?.Price?.RegularPrice?.PriceTaxIncl;
    },
    promotionPrice() {
      return this.product?.Price?.PromotionalPrice?.PriceTaxIncl;
    },
    promotionValue() {
      return this.product?.Price?.PromotionalPrice?.PriceRuleName;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    urlCategory() {
      try {
        const seo = this.product?.Category?.Main?.SEO;
        if (seo) {
          const mainCategory = seo?.find((item) => {
            return (
              this.$i18n.locale.toLocaleLowerCase() ===
              item.LanguageIsoCode.toLocaleLowerCase()
            );
          });
          if (!mainCategory) {
            return 'product';
          }
          if (mainCategory.URL) {
            return mainCategory.URL;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return 'product';
    },

    urlProduct() {
      // try {
      //   const seo = this.product.SEO;
      //   if (seo) {
      //     const productSeo = seo?.find((item) => {
      //       return (
      //         this.$i18n.locale.toLocaleLowerCase() ===
      //         item.LanguageIsoCode.toLocaleLowerCase()
      //       );
      //     });
      //     if (!productSeo) {
      //       return this.product.IdProduct;
      //     }
      //     if (productSeo.ProductDescriptionFriendlyURL) {
      //       return productSeo.ProductDescriptionFriendlyURL;
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      return this.product?.SEO?.URL;
    },

    link() {
      try {
        if (this.product.SEO) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      } catch (error) {}

      return '#';
    },
  },
  methods: {
    position(lookbookProduct) {
      const row = Number(lookbookProduct.row.split('_')[1]);
      const col = Number(lookbookProduct.column.split('_')[1]);

      const top = (row / this.nbrRow) * 100;
      const left = (col / this.nbrCol - 1 / (this.nbrCol * 2)) * 100;

      return {
        top: top + '%',
        left: left + '%',
      };
    },
    display(lookbookProduct) {
      const col = Number(lookbookProduct.column.split('_')[1]);

      if (col >= this.nbrCol - 1) {
        return 'right';
      }
      return 'left';
    },
    showLink(val) {
      this.hovered = val;
    },
  },
  components: { ChevronRightIcon },
};
