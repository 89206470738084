
import Teleport from 'vue2-teleport';
import { Drawer } from 'element-ui';
import cartMixin from '@/mixins/cartMixin.js';
export default {
  props: {
    variants: {
      type: Array,
    },
    product: {
      type: Object,
    },
    variantSelected: {
      type: Object,
      default: null,
    },
  },
  mixins: [cartMixin],
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    getAttrs(variant) {
      let att = {};
      variant?.Combination?.Attributes?.map((item) => {
        if (item.GroupName === 'Color') {
          att = {
            ...att,
            ...{
              color: item,
            },
          };
        } else if (item.GroupName === 'Size') {
          att = {
            ...att,
            ...{
              size: item,
            },
          };
        }
      });

      return att;
    },
    addToCart(variant) {
      const idProductAttributeSelected = variant.Combination.IdProductAttribute;

      this.cartService
        .addToCart({
          idCart: this.cart.IdCart,
          product: this.product,
          idProductAttribute: idProductAttributeSelected,
        })
        .then((data) => {
          this.cartService.fetchCart({
            onSuccess: (data) => {
              this.drawer = false;
              this.openRightBarCart();
            },
          });

          // this.$gtag.event('add_to_cart', {
          //   event_category: 'Cart',
          //   event_label: 'Product : ' + this.productTitle,
          //   value: 'Id product : ' + this.product.IdProduct,
          // });

          this.$gtag.event('add_to_cart', {
            items: [
              {
                item_id: this.product.IdProduct,
                quantity: 1,
              },
            ],
          });

          fbq('track', 'InitiateCheckout', {
            content_ids: [this.product.IdProduct + '-' + this.productTitle],
            eventref: '', // or set to empty string
          });
        });
    },
    openDrawer() {
      this.drawer = true;
    },
  },
  components: {
    Teleport,
    Drawer,
  },
};
