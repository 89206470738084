
export default {
  data() {
    return {
      siteKey: process.env.CAPTCHA_SITE_KEY,
    };
  },
  mounted() {
    this.renderRecaptcha();
  },
  methods: {
    renderRecaptcha() {
      window?.grecaptcha?.render(this.$refs.recaptchaContainer, {
        sitekey: this.siteKey,
        callback: this.onCaptchaVerified,
      });
    },
    onCaptchaVerified(response) {
      // Handle verified captcha response
      console.log('Captcha verified:', response); //2RW4EW
    },
  },
  head() {
    return {
      script: [
        {
          src: 'https://www.google.com/recaptcha/api.js?render=explicit',
          async: true,
          defer: true,
        },
      ],
    };
  },
};
