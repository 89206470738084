
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    safeLink(link) {
      let url = this.localePath(link);
      if (link.includes('http')) {
        const listWords = link.split('/');
        url = '';
        for (let i = 3; i < listWords.length; i++) {
          const element = listWords[i];
          url += '/' + element;
        }
        return url;
      }
      if (link[0] !== '/') {
        url = this.localePath('/' + link);
      }

      return url;
    },
  },
};
