
import CarouselImages from '@/components/pages/product/CarouselImages.vue';
import ProductInformations from '@/components/pages/product/ProductInformations.vue';
import ProductLoading from '@/components/pages/product/ProductLoading.vue';
import ProductRelationship from '@/components/pages/product/ProductRelationship.vue';
import ProductSizes from '@/components/pages/product/ProductSizes.vue';
import { Button, Collapse, CollapseItem } from 'element-ui';
import ProductNotAvailable from '@/components/products/ProductNotAvailable.vue';
import { MapPinIcon } from 'vue-feather-icons';
import cartMixin from '@/mixins/cartMixin.js';
import PageNotFound from '@/layouts/components/PageNotFound.vue';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';

// const fetchProduct = async (store, { permalink }) => {
//   return await store.dispatch("product/fetchProduct", {
//     permalink,
//   });
// };
// const pageNotFound = (context) => {
//   context.error({
//     statusCode: 404,
//     message: context.app.i18n.t("the product you requested no longer exists"),
//   });
// };

export default {
  layout: 'MainLayout',
  name: 'ProductPageShopLook',
  auth: false,
  mixins: [cartMixin],

  data() {
    return {
      loading: true,
      notFound: false,
      productsRelative: [],
    };
  },
  computed: {
    product() {
      return this.$store.state.product.currentProduct;
    },

    productTitle() {
      if (this.product != null) {
        try {
          return this.product.Description.Title;
        } catch (error) {}
      }
      return '';
    },
    productImages() {
      try {
        if (this.product.Media) {
          return this.product.Media.Images.product_large;
        }
      } catch (error) {}

      return [];
    },
    productImagesXLarge() {
      try {
        if (this.product.Media) {
          return this.product.Media.Images.product_xlarge;
        }
      } catch (error) {}

      return [];
    },
    productVideos() {
      try {
        if (this.product.Media) {
          return this.product.Media.Videos;
        }
      } catch (error) {}

      return [];
    },
  },
  methods: {
    getImageWithIndex(index) {
      try {
        return this.productImagesXLarge[index];
      } catch (error) {}
    },
    async permalinkLangUrl() {
      const links = {};

      if (this.product?.SEO) {
        this.product?.SEO?.map((item, index) => {
          const mainCategory = this.product?.Category?.Main?.SEO?.find(
            (catSeo) => {
              return (
                item.LanguageIsoCode.toLocaleLowerCase() ===
                catSeo.LanguageIsoCode.toLocaleLowerCase()
              );
            }
          );

          links[item.LanguageIsoCode.toLowerCase()] = {
            cat: mainCategory?.URL,
            product: item.URL,
          };
        });
        await this.$store.dispatch('i18n/setRouteParams', links);
      }
    },
    async getProductRelative() {
      try {
        const permalink = String(this.$route.params.product);
        const words = permalink.split('-');
        const id = words[words.length - 1];
        const productHelpers = new ProductHelpers(this.$http, this);
        const params = {
          idProduct: id,
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        };

        const data = await productHelpers.productsAssociation(params);
        this.productsRelative = data;
      } catch (error) {
        console.log('error', error);
      }
    },
    handleScroll(event) {},
  },
  mounted() {
    this.$store.commit('shared/menu/setAlwaysMenuSticky', true);
    this.$store.commit('shared/topBanner/setAlwaysDisable', true);
    const permalink = this.$route.params.product;
    this.$store
      .dispatch('product/fetchProduct', {
        permalink,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        this.permalinkLangUrl();
        this.getProductRelative();
      })
      .catch((err) => {
        this.notFound = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  created() {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },

  unmounted() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  destroyed() {
    this.$store.commit('shared/menu/setAlwaysMenuSticky', false);
    this.$store.commit('shared/topBanner/setAlwaysDisable', false);
  },

  components: {
    CarouselImages,
    ProductInformations,
    ProductRelationship,
    ProductSizes,
    Button,
    ProductNotAvailable,
    Collapse,
    CollapseItem,
    MapPinIcon,
    ProductLoading,
    PageNotFound,
  },
};
