
import { Button, Dialog, Select, Option, Label } from 'element-ui';
import { LocalisationHelper } from '@/helpers/LocalisationHelper';
export default {
  data() {
    const localisationHelper = new LocalisationHelper(
      this.$http,
      this.$store,
      this.$auth,
      this.$i18n.locale
    );

    const defaultCountryIsoCode = localisationHelper.getAppCountryIsoCode();

    return {
      localisationHelper,
      langueValue: this.$i18n.locale,
      countryValue: defaultCountryIsoCode,
      currencyValue: '',
    };
  },

  created() {},
  async mounted() {
    this.loadPays();
    this.currencyValue =
      this.localisationHelper.getAppCurrency().CurrencyIsoCode;
  },
  computed: {
    showLocalisation: {
      get() {
        return this.$store.state.shop.localisation.localisationDialogState;
      },
      set(value) {
        this.$store.commit('shop/localisation/toggleDialog', value);
      },
    },
    languages() {
      return this.$i18n.localeCodes;
    },
    countries() {
      return this.localisationHelper.getCountries();
    },
    currencies() {
      const selectedCountries = this.countries.filter((item) => {
        return item.CountryIsoCode === this.countryValue;
      });

      if (selectedCountries.length) {
        return selectedCountries[0].CountryCurrencies;
      }
      return null;
    },
    countryIsoCodeSelectd() {
      const countrySelectedIndex = this.countries.findIndex(
        (item) => item.IdCountry === this.countryValue
      );
      // this.langueValue = this.countries[countrySelectedIndex].LanguageIsoCode;

      return this.countries[countrySelectedIndex];
    },

    currencySelectd() {
      const currencySelectedIndex = this.currencies.findIndex(
        (item) => item.CurrencyIsoCode === this.currencyValue
      );

      return this.currencies[currencySelectedIndex];
    },
  },
  methods: {
    showDefaultVal() {
      this.localisationHelper.setAppCountryIsoCode(this.countryValue);

      this.localisationHelper.setAppCurrency({
        currencyIsoCode: this.currencyValue,
        currencySign: this.currencySelectd.CurrencySign,
      });

      this.$i18n.setLocale(this.langueValue);
      // this.showLocalisation = false;
      // switchLocalePath(lg);

      if (process.client) {
        window.location.href = this.switchLocalePath(this.langueValue);
      }
    },
    onCountrySelected() {
      const countrySelected = this.countries.find((item) => {
        return item.CountryIsoCode === this.countryValue;
      });

      const currencyList = countrySelected?.CountryCurrencies;

      if (currencyList.length > 0) {
        const currencyIndex = currencyList.findIndex(
          (item) => item.IsMainCurrency === true
        );

        if (currencyIndex != -1) {
          this.currencyValue = currencyList[currencyIndex].CurrencyIsoCode;
        } else {
          this.currencyValue = currencyList[0].CurrencyIsoCode;
        }
      }

      const lgIndex = this.languages.findIndex(
        (item) => item === countrySelected.LanguageIsoCode
      );

      if (lgIndex !== -1) {
        this.langueValue = this.languages[lgIndex];
      }
    },

    loadPays() {
      const local = this.$i18n.locale;
      const localisationHelper = new LocalisationHelper(
        this.$http,
        this.$store,
        this.$auth,
        local ? local : 'fr'
      );

      // await localisationHelper.setListLanguages();
      // await localisationHelper.setListCountries();
      localisationHelper.init();
      localisationHelper.setListCountries();
    },
  },
  components: {
    Button,
    Dialog,
    Select,
    Option,
    Label,
  },
};
