import { render, staticRenderFns } from "./MenuLeft.vue?vue&type=template&id=c5eb8f8c&scoped=true"
import script from "./MenuLeft.vue?vue&type=script&lang=js"
export * from "./MenuLeft.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5eb8f8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubMenu: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/SubMenu.vue').default})
