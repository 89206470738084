
import { Input, Button, Form, FormItem } from 'element-ui';
import { CustumerHelpers } from '@/helpers/CustumerHelpers';
import ContentLayout from '@/pages/content/components/ContentLayout.vue';
import Captcha from '../components/Captcha.vue';
import { CheckCircleIcon } from 'vue-feather-icons';
export default {
  layout: 'MainLayout',
  name: 'ShopPage',
  auth: false,

  data() {
    return {
      loading: false,
      success: false,
      formFields: {
        firstname: '',
        lastname: '',
        email: '',
        subject: '',
        message: '',
      },
      rules: {
        firstname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        subject: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],

        email: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
        message: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },

          {
            min: 6,
            message: this.$t('At least {0} characters', [3]),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    sumbit() {
      const custumerHelpers = new CustumerHelpers(this.$http, this.$auth);
      this.$refs['contactusForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            Firstname: this.formFields.firstname,
            Lastname: this.formFields.lastname,
            Email: this.formFields.email,
            Subject: this.formFields.subject,
            Message: this.formFields.message,
          };

          try {
            await custumerHelpers.createTicketContact(data);
            this.formFields = {
              firstname: '',
              lastname: '',
              email: '',
              subject: '',
              message: '',
            };
            this.success = true;
          } catch (error) {
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    breadcrumbLinks() {
      const links = [
        {
          link: '/',
          text: this.$t('Home'),
        },
        {
          text: this.$t('Contact-us'),
        },
      ];
      return links;
    },
  },
  components: {
    Input,
    Button,
    Form,
    FormItem,
    ContentLayout,
    Captcha,
    CheckCircleIcon,
  },
};
