
import CardAddress from "./CardAddress.vue";
import { Button } from "element-ui";
import { PlusIcon } from "vue-feather-icons";
import OrderCard from "./OrderCard.vue";
import FavoriesItems from "./FavoriesItems.vue";
import favoriteMixin from '@/mixins/favoriteMixin.js';

export default {
  name: "MyAddress",
  mixins: [favoriteMixin],
  components: {
    CardAddress,
    Button,
    PlusIcon,
    OrderCard,
    FavoriesItems,
  },
  data() {
    return {
    };
  },
  computed: {
    productFavoritesSize() {
      return this.favorite?.Products?.length;
    },
  },
  mounted() {
  },
};
