
import { Input, Button, Collapse, CollapseItem } from 'element-ui';
import { CreditCardIcon } from 'vue-feather-icons';
import LoginForm from '@/pages/authenticate/components/LoginForm.vue';
import PrivacyBloc from '@/pages/authenticate/components/PrivacyBloc.vue';
import RegisterForm from '@/pages/authenticate/components/RegisterForm.vue';
import RegisterFormInfo from '@/pages/authenticate/components/RegisterFormInfo.vue';
import RegisterFormGuest from '@/pages/authenticate/components/RegisterFormGuest.vue';
import RegisterFormInfoGuest from '@/pages/authenticate/components/RegisterFormInfoGuest.vue';
import NewCompte from '@/pages/authenticate/components/NewCompte.vue';
import SlideDownUp from '@/components/transitions/SlideDownUp.vue';

export default {
  layout: 'MainLayout',
  name: 'LoginPage',
  // middleware: 'auth',
  auth: 'guest',
  data() {
    return {
      activeName: '0',
      email: null,
    };
  },
  created() {
    this.$store.commit('shared/menu/toggle', {
      tabActive: 1,
      open: false,
    });
  },
  mounted() {
    this.isLogin();

    const route = this.$route.params.route;
    if (route === 'login') {
      this.activeName = '1';
    } else if (route === 'register') {
      this.activeName = '2';
    } else if (route === 'register-info') {
      this.activeName = '3';
    } else if (route === 'register-guest-info') {
      this.activeName = '4';
    }
  },
  methods: {
    goTo(v, $event = null) {
      this.activeName = v;
      if (v === '1') {
        history.pushState({}, null, 'login');
      } else if (v === '2') {
        if ($event.query.redirect) {
          history.pushState({}, null, 'register?redirect=2');
        } else {
          history.pushState({}, null, 'register');
        }
      } else if (v === '3') {
        this.email = $event.email;
        if ($event.route.query.redirect) {
          history.pushState({}, null, 'register-info?redirect=2');
        } else {
          history.pushState({}, null, 'register-info');
        }
      } else if (v === '4') {
        this.email = $event.email;
        if ($event.route.query.redirect) {
          history.pushState({}, null, 'register-guest-info?redirect=2');
        } else {
          history.pushState({}, null, 'register-guest-info');
        }
      }
    },

    rdirectTo() {
      let redirectTo = '/account/informations';
      if (this.$route.query.redirect) {
        redirectTo = '/cart/delivery';
      }
      this.$router.push({
        path: this.localePath(redirectTo),
      });
    },
    isLogin() {},
  },

  watch: {
    '$route.params'(newQueryParams) {},
  },

  components: {
    Input,
    CreditCardIcon,
    Button,
    LoginForm,
    PrivacyBloc,
    RegisterForm,
    RegisterFormInfo,
    RegisterFormGuest,
    RegisterFormInfoGuest,
    Collapse,
    CollapseItem,
    NewCompte,
    SlideDownUp,
  },
};
