
import {
  Select,
  Option,
  Drawer,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import { FilterIcon, SlidersIcon, XIcon } from "vue-feather-icons";

export default {
  props: {
    nbrProduct: {
      type: Number,
      default: 0,
    },
    categoryTitle: {
      type: String,
    },
    sizes: {
      type: Array,
    },
    colors: {
      type: Array,
    },
    price: {
      type: Object,
    },
    showSort: {
      type: Boolean,
      default: true,
    },
    showFilterBtn: {
      type: Boolean,
      default: true,
    },
    h1: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let SortOptions = {
      options: [
        {
          value: "default",
          label: this.$t("By default"),
        },
        {
          value: "price_asc",
          label: this.$t("Ascending price"),
        },
        {
          value: "price_desc",
          label: this.$t("Decreasing price"),
        },
        {
          value: "newest_desc",
          label: this.$t("Newest"),
        },
        {
          value: "newest_asc",
          label: this.$t("Oldest"),
        },
      ],
      value: "",
    };
    return {
      SortOptions,
      drawer: false,
      activeCollapseNames: ["1"],
      filterData: {
        filterIdAttributes: "",
        filterMinPriceTaxExcl: 0,
        filterMaxPriceTaxExcl: 0,
        idAttributes: {
          colors: [],
          sizes: [],
        },
      },
      colorsSelected: [],
      sizesSelected: [],
      //----data
    };
  },
  computed: {
    listSizes() {
      return this.sizes;
    },
    listColors() {
      return this.colors;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    idAttributes() {
      let idAttributesSelected = "";

      this.filterData.idAttributes.sizes = [];
      this.filterData.idAttributes.colors = [];

      this.sizesSelected.map((item) => {
        this.filterData.idAttributes.sizes.push(item);
        idAttributesSelected += item.IdAttribute + ",";
      });

      this.colorsSelected.map((item) => {
        this.filterData.idAttributes.colors.push(item);
        idAttributesSelected += item.IdAttribute + ",";
      });

      return idAttributesSelected;
    },

    btnResetSort() {
      return Object.keys(this.$route.query).includes("sortby");
    },
    btnResetFilter() {
      const routeQuery = Object.keys(this.$route.query);
      if (routeQuery.length) {
        if (routeQuery.length === 1) {
          if (!routeQuery.includes("sortby")) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    sortBy() {
      switch (this.SortOptions.value) {
        case "price_asc":
          return {
            sortby: "SortByPrice",
            value: "ASC",
          };
        case "price_desc":
          return {
            sortby: "SortByPrice",
            value: "DESC",
          };
        case "newest_desc":
          return {
            sortby: "SortByNewest",
            value: "DESC",
          };
        case "newest_asc":
          return {
            sortby: "SortByNewest",
            value: "ASC",
          };
        default:
          return {
            sortby: "Default",
            value: "DESC",
          };
      }
    },
  },
  methods: {
    openDrawer() {
      this.drawer = true;
    },
    closeDrawer() {
      this.drawer = false;
    },
    onSizesSelected($event) {
      this.sizesSelected = $event.sizes;
    },
    onColorsSelected($event) {
      this.colorsSelected = $event.colors;
    },
    priceChange($event) {
      // this.filterData.filterMinPriceTaxExcl = Number($event[0]);
      // this.filterData.filterMaxPriceTaxExcl = Number($event[1]);
    },
    filter() {
      this.filterData.filterIdAttributes = this.idAttributes.slice(0, -1);
      const prices = this.$refs.filterPrice.value;
      this.filterData.filterMinPriceTaxExcl = Number(prices[0]);
      this.filterData.filterMaxPriceTaxExcl = Number(prices[1]);

      this.$emit("submit", {
        filterData: this.filterData,
      });
    },
    resetFilter() {
      this.filterData = {
        filterIdAttributes: "",
        filterMinPriceTaxExcl: 0,
        filterMaxPriceTaxExcl: 0,
        idAttributes: {
          colors: [],
          sizes: [],
        },
      };
      this.colorsSelected = [];
      this.sizesSelected = [];
      const e = {
        type: "filter",
        filterData: this.filterData,
      };
      this.$emit("reset", e);
    },
    resetSort() {
      this.SortOptions.value = "";
      const e = {
        type: "sort",
        filterData: this.filterData,
      };
      this.$emit("reset", e);
    },
    onSort() {
      this.$emit("onSort", this.sortBy);
    },
    clear() {},
  },
  mounted() {
    this.SortOptions.value = this.$route.query.sortby
      ? this.$route.query.sortby
      : "";
  },
  activated() {
    this.filterData.filterMinPriceTaxExcl = this.minPrice;
    this.filterData.filterMaxPriceTaxExcl = this.maxPrice;
  },
  components: {
    Select,
    Option,
    Drawer,
    Button,
    SlidersIcon,
    Collapse,
    CollapseItem,
    FilterIcon,
    XIcon,
  },
};
