
import FilterOptions from '@/components/pages/category/FilterOptions.vue';
import TrieOptions from '@/components/pages/category/TrieOptions.vue';
export default {
  props: {
    nbrProduct: {
      type: Number,
      default: 0,
    },
    categoryTitle: {
      type: String,
    },
    h1: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      event: {},
    };
  },
  computed: {
    idsAttribute() {
      return this.$store.state.newCategory.idsAttribute;
    },
    prices() {
      return this.$store.state.newCategory.prices;
    },
    promotion() {
      return this.$store.state.newCategory.promotion;
    },
    orderBy() {
      return this.$store.state.newCategory.orderBy;
    },
  },
  mounted() {
    this.event = {
      idsAttribute: this.idsAttribute,
      prices: this.prices,
      sort: this.orderBy,
    };
  },
  methods: {
    filter() {
      const $e = {
        idsAttribute: this.idsAttribute,
        prices: this.prices,
        promotion: this.promotion,
      };
      this.event = { ...this.event, ...$e };
      this.$emit('onFilter', this.event);
    },
    sort() {
      const $e = {
        sort: this.orderBy,
      };
      this.event = { ...this.event, ...$e };
      this.$emit('onSort', this.event);
    },
  },
  components: { FilterOptions, TrieOptions },
};
