
import { CreditCardIcon } from 'vue-feather-icons';
import AccountBody from '@/pages/account/components/AccountBody.vue';
import LoyaltyProgress from '@/pages/account/components/LoyaltyProgress.vue';
import { Button } from 'element-ui';
import NewsLetterCheckbox from '@/components/NewsLetterCheckbox.vue';

export default {
  layout: 'account',
  name: 'profilHomePage',
  middleware: 'auth',

  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
  },
  mounted() {
    this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer', {
      onSuccess: () => {},
    });
  },

  components: {
    AccountBody,
    CreditCardIcon,
    LoyaltyProgress,
    Button,
    NewsLetterCheckbox,
  },
};
