
import CustomerService from '@/services/CustomerService';
export default {
  middleware: 'auth',
  props: {
    redirectToLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
      appId: null,
      isConnected: false,
      account: {
        firstName: '',
        lastName: '',
        email: '',
        personalID: '',
      },
      FB: undefined,
    };
  },
  methods: {
    hideRightBar() {
      this.$store.commit('shared/menu/toggle', {
        tabActive: 1,
        open: false,
      });
    },
    getUserData() {
      if (this.FB) {
        this.FB.api(
          '/me',
          'GET',
          { fields: 'id,name,email' },
          (userInformation) => {
            let name = userInformation.name.split(' ');
            this.account.personalID = userInformation.id;
            this.account.email = userInformation.email;
            this.account.firstName = name[0];
            this.account.lastName = name.length > 1 ? name[1] : name[0];

            this.$store.commit('shared/menu/toggle', {
              tabActive: 1,
              open: false,
            });
            this.login();
          }
        );
      }
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
    },
    onLogin() {
      // if (this.isConnected) {
      this.getUserData();
      // }
    },
    onLogout() {
      this.isConnected = false;
    },
    async login() {
      this.customerService
        .loginWithFacebook({
          facebookCustomerId: this.account.personalID,
          email: this.account.email,
          firstName: this.account.firstName,
          lastName: this.account.lastName,
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        })
        .then((data) => {
          this.hideRightBar();
          this.$gtag.event('login', { method: 'Google' });
          this.rdirectTo();
        })
        .catch((err) => {});
    },
    rdirectTo() {
      let redirectTo = { name: 'account' };

      if (this.redirectToLink) {
        redirectTo = this.redirectToLink;
      } else {
        if (this.$route.query.redirect) {
          if (this.$route.query.redirect === 'loyalty-program') {
            redirectTo = `/account/loyalty/membership`;
          } else {
            redirectTo = '/cart/delivery';
          }
        }
      }

      this.$router.push({
        path: this.localePath(redirectTo),
      });
    },
  },

  mounted() {
    this.appId = process.env.FACEBOOK_APP_ID;
  },
  activated() {},
};
