
import CartMain from './components/CartMain.vue';
import StepDelivery from './components/StepDelivery.vue';
import cartMixin from '@/mixins/cartMixin.js';
import CartEmptyError from '@/pages/cart/components/CartEmptyError.vue';

export default {
  layout: 'tunnel',
  name: 'deliveryCartPage',
  mixins: [cartMixin],
  // middleware: ["Tunnel", "mdAddress"],
  data() {
    return {
      valide: false,
      loadedCart: false,
      hasProduct: false,
    };
  },
  computed: {
    guestUser() {
      return this.$store.state.user.guest.guestUser;
    },

    isLogin() {
      return this.$auth.loggedIn || this.guestUser;
    },
  },
  created() {
    this.loadedCart = false;
    this.cartService.fetchCart({
      onSuccess: () => {
        const listItemLocal = this.cart?.Total?.Product?.Quantity;
        if (listItemLocal !== 0) {
          this.hasProduct = true;
        }
        this.loadedCart = true;
      },
      onError: () => {
        this.loadedCart = true;
      },
    });
  },
  components: {
    CartMain,
    StepDelivery,
    CartEmptyError,
  },
  //If Delivery Tabs is not in StepDelivery Reload
  watch: {
    $route(to, from) {
      const hasDeliveryTabs = this.$refs.stepDelivery.$refs.deliveryTabs;
      if (!hasDeliveryTabs) {
        window.location.reload();
      }
    },
  },
  mounted() {},
  methods: {},
};
