
import { FacebookIcon, LinkIcon, MailIcon } from 'vue-feather-icons';
import { Tabs, TabPane, Button } from 'element-ui';

export default {
  head() {
    return {
      script: [
        {
          src: 'https://connect.facebook.net/en_US/sdk.js',
          // async: true,
          callback: () => {
            this.facebookSharIsLoaded = true;
            this.loadedFacebook();
          },
        },
      ],
    };
  },
  props: {
    sponsorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 'link',
      facebookSharIsLoaded: false,
    };
  },
  computed: {
    linkToShare() {
      return `${process.env.URL}${this.$i18n.locale}/${'referral'}/${
        this.sponsorData?.Link
      }`;
    },
  },
  methods: {
    shareOnFacebook() {
      if (process.client) {
        // const shareUrl = this.linkToShare; // URL you want to share
        // const shareText = "Check out this image and text!";
        // const shareImage =
        //   "https://dev.jeanmarcphilippe.com/_nuxt/img/refer.6b3064f.jpg"; // URL of the image you want to share
        // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&kid_directed_site=0&sdk=joey&u=${shareImage}&display=popup&ref=plugin&src=share_button`;
        // window.open(facebookShareUrl, "_blank");
      }
      FB.ui(
        {
          method: 'share',
          href: this.linkToShare,
          quote: this.$t('refer_form_step_facebook_title'),
          hashtag: this.$t('refer_form_step_facebook_title'),
          picture: 'https://dev.jeanmarcphilippe.com/assets/img/refer2.jpg',
        },
        function (response) {}
      );
    },
    copyPromoCode(text) {
      if (process.client) {
        navigator.clipboard.writeText(text);
        this.$notify({
          title: this.$t('Copied'),
          dangerouslyUseHTMLString: true,
          message: `<span style='word-break: break-all;'>${this.linkToShare}</span>`,
          type: 'success',
        });
      }
    },
    loadedFacebook() {
      FB.init({
        appId: '1250419325746258',
        version: 'v10.0',
      });
    },
  },
  components: {
    LinkIcon,
    Tabs,
    TabPane,
    MailIcon,
    FacebookIcon,
    Button,
  },
};
