
import { Button } from 'element-ui';
export default {
  props: {
    imgurl: {
      type: String,
    },
    imgurlMb: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    buttonLink: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: '',
    },
    linkTitle: {
      type: String,
      default: 'Discover',
    },
    square: {
      type: Boolean,
      default: false,
    },
    h1: {
      type: Boolean,
      default: false,
    },
    ImgNotLazy: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    videoUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    heightImg() {
      if (this.height) {
        return this.height;
      }

      if (this.$SETTINGS.isMobile) {
        return 320;
      } else {
        return 680;
      }
    },
    widthImg() {
      if (this.width) {
        return this.width;
      }

      if (this.$SETTINGS.isMobile) {
        return 680;
      } else {
        return 1366;
      }
    },
    vdScale() {
      if (process.client) {
        return (window.innerHeight / window.innerWidth) * 2;
      }

      return 1;
    },
    getImageMobile() {
      if (this.imgurlMb) {
        return this.imgurlMb;
      }
      return this.imgurl;
    },
  },
  mounted() {},
  components: {
    Button,
  },
};
