
import { ChevronDownIcon } from 'vue-feather-icons';
import OrderDetailsContent from './OrderDetailsContent.vue';
export default {
  props: {
    type: {
      type: String,
      default: 'details',
    },
    state: {
      type: String,
      default: 'default',
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listIsOpen: false,
    };
  },
  computed: {
    // shippingDate() {
    //   const date = new Date(this.order.ShippingDate);
    //   let formattedDate = `${("0" + date.getDate()).slice(-2)}/${(
    //     "0" +
    //     (date.getMonth() + 1)
    //   ).slice(-2)}/${date.getFullYear()}`;

    //   return formattedDate;
    // },
    total() {
      return Number(this.order?.Total?.Paid?.TaxIncl)?.toFixed(2);
    },
    orderStateClass() {
      return this.order?.State?.Label;
    },
    orderDate() {
      let dateOrder = this.order.DateAdd;
      return dateOrder;
    },
  },
  mounted() {},
  components: {
    ChevronDownIcon,
    OrderDetailsContent,
  },
};
