
import { Select, Option, Button } from 'element-ui';
import { ProductHelpers } from '~/helpers/ProductHelpers';
export default {
  props: {
    isShowed: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showReturnForm: false,
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      sizes: [],
      reasonsValue: null,
      reasonTypeValue: -1,
      variantSelected: null,
      valid: {
        state: true,
        msg: this.$t('All is ok') + ' !',
      },
    };
  },
  computed: {
    reasons() {
      return this.$store.state.shop.returns.reasons;
    },
    resonSelected() {
      const list = this.reasons.filter((item) => {
        return item.IdOrderReturnReasonCode === this.reasonsValue;
      });

      if (list.length > 0) {
        return list[0];
      }
      return null;
    },
  },

  methods: {
    selectReasonType(type) {
      if (type === 0) {
        this.variantSelected = null;
      }
      this.reasonTypeValue = type;
      this.loadSizes();
    },
    async loadSizes() {
      if (this.sizes.length === 0) {
        const data = await this.productHelpers.productAvailability({
          productId: this.product.IdProduct,
          LanguageIsoCode: this.$i18n.locale,
        });

        if (data) {
          this.sizes = data;
        }
      }
    },
    checkIsValid() {
      if (this.isShowed) {
        if (this.reasonsValue === null) {
          this.valid = {
            state: false,
            msg: this.$t('Please choose the reason for the return') + ' !',
          };
        } else if (this.reasonTypeValue === -1) {
          this.valid = {
            state: false,
            msg: this.$t('Please choose the type of return') + ' !',
          };
        } else if (this.reasonTypeValue === 0) {
          this.valid = {
            state: true,
            msg: this.$t('All is ok') + ' !',
          };
        } else if (this.variantSelected === null) {
          this.valid = {
            state: false,
            msg: this.$t('Please choose your size') + ' !',
          };
        } else {
          this.valid = {
            state: true,
            msg: this.$t('All is ok') + ' !',
          };
        }
      } else {
        this.valid = {
          state: true,
          msg: this.$t('All is ok') + ' !',
        };
      }

      return this.valid;
    },
    getSizeFromVariant(variant) {
      return variant?.Combination?.Attributes?.find(
        (item) => item?.GroupName === 'Size'
      );
    },
  },
  components: {
    Select,
    Option,
    Button,
  },
};
