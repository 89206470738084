
export default {
  props: {
    idMenuColumn: {
      type: Number,
    },
    items: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    leftItems() {
      try {
        return this.items[0];
      } catch (error) {
        return null;
      }
    },
    centerItems() {
      try {
        return this.items[1];
      } catch (error) {
        return null;
      }
    },
    rightItems() {
      try {
        if (this.items.length > 2) {
          return this.items[2];
        } else if (this.items.length === 1) {
          return this.items[0];
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    // rightItems() {
    //   try {
    //     if (this.items.length > 2) {
    //       return this.items[2];
    //     } else {
    //       return this.items[0];
    //     }
    //   } catch (error) {
    //     return null;
    //   }
    // },
  },
  methods: {
    getImage(Media) {
      try {
        return Media.Images.menu_medium;
      } catch (error) {
        return null;
      }
    },
    getSafeLink(link) {
      if (link.includes('http')) {
        const parsedUrl = new URL(link); // Create a URL object
        return parsedUrl.pathname;
      } else {
        return this.localePath('/' + link);
      }
    },
  },
};
