import { render, staticRenderFns } from "./TopBanner.vue?vue&type=template&id=88ac4eb4&scoped=true"
import script from "./TopBanner.vue?vue&type=script&lang=js"
export * from "./TopBanner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ac4eb4",
  null
  
)

export default component.exports