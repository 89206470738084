import { render, staticRenderFns } from "./_product.vue?vue&type=template&id=d1716e50"
import script from "./_product.vue?vue&type=script&lang=js"
export * from "./_product.vue?vue&type=script&lang=js"
import style0 from "./_product.vue?vue&type=style&index=0&id=d1716e50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageProduct: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ImageProduct.vue').default,ProductCardShopLook: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ProductCard/ProductCardShopLook.vue').default})
