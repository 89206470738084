
import { Button, Drawer, CollapseItem, Collapse, Slider } from 'element-ui';
import { XIcon, SlidersIcon } from 'vue-feather-icons';

export default {
  data() {
    return {
      drawer: false,
      activeCollapseNames: '',
      // idsAttribute: [],
      price: {
        value: [0, 0],
      },
      promotion: false,
    };
  },
  computed: {
    filterOptions() {
      return this.$store.state.newCategory.filterOptions;
    },
    filterAttribute() {
      try {
        return this.filterOptions.FilterAttribute.AttributeGroup;
      } catch (error) {}

      return [];
    },

    //price
    filterPrice() {
      try {
        return this.filterOptions.FilterPrice.RegularPrice;
      } catch (error) {}
      return {};
    },
    min() {
      return this.filterPrice.MinPriceTaxIncl;
    },
    max() {
      return this.filterPrice.MaxPriceTaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    spanMinLeft() {
      return (
        ((this.price.value[0] - this.min) / (this.max - this.min)) * 100 + '%'
      );
    },
    spanMaxLeft() {
      return (
        ((this.price.value[1] - this.min) / (this.max - this.min)) * 100 + '%'
      );
    },
    // filter option selected
    idsAttribute() {
      return this.$store.state.newCategory.idsAttribute;
    },
    prices() {
      return this.$store.state.newCategory.prices;
    },
    
    inPromotion() {
      return this.$store.state.newCategory.promotion;
    },

    isFiltred() {
      let filtred = false;
      try {
        if (this.idsAttribute.length) {
          filtred = true;
        }
        if (this.pricesAreSelected()) {
          if (
            Number(this.prices[0]) !== this.min ||
            Number(this.prices[1]) !== this.max
          ) {
            filtred = true;
          }
        }
      } catch (error) {}

      return filtred;
    },
  },
  methods: {
    openDrawer() {
      this.drawer = true;
    },
    closeDrawer() {
      this.drawer = false;
    },
    filter(clearPrice = false) {
      let prices = [];
      if (!clearPrice) {
        prices = this.price.value;
      }
      this.$store.commit('newCategory/setPrices', { prices });
      this.$store.commit('newCategory/setInPromotion', {
        promotion: this.promotion,
      });
      this.drawer = false;
      this.$emit('onFilterClick');
    },
    resetFilter() {
      this.$store.commit('newCategory/clearIdsAttribute');
      this.$store.commit('newCategory/clearPrices');
      this.price.value = [this.min, this.max];
      this.filter(true);
    },
    isIdAttributeSelected(idAttribute) {
      try {
        return this.idsAttribute.find(
          (item) => Number(item) === Number(idAttribute)
        );
      } catch (error) {}
      return false;
    },
    addIdAttribute(idAttribute) {
      if (this.isIdAttributeSelected(idAttribute)) {
        this.$store.commit('newCategory/removeIdAttribute', {
          idAttribute,
        });
      } else {
        this.$store.commit('newCategory/addIdAttribute', {
          idAttribute,
        });
      }
    },
    pricesAreSelected() {
      if (this.prices.length) {
        if (Number(this.prices[0]) !== NaN && Number(this.prices[1])) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    const permalink = this.$route.params.cat;
    if (permalink) {
      await this.$store.dispatch('newCategory/fetchFiltersOptions', {
        permalink,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      });

      if (this.pricesAreSelected()) {
        this.price.value = [Number(this.prices[0]), Number(this.prices[1])];
      } else {
        this.price.value = [this.min, this.max];
      }

      if(this.inPromotion){
        this.promotion = this.inPromotion
      }
    }
  },
  components: {
    Button,
    XIcon,
    SlidersIcon,
    Drawer,
    CollapseItem,
    Collapse,
    Slider,
  },
};
