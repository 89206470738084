
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Orders from "@/pages/account/components/Orders.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import OrderDetailsContent from "@/pages/account/components/OrderDetailsContent.vue";
import OrderCard from "@/pages/account/components/OrderCard.vue";
import { ShoppingBagIcon } from "vue-feather-icons";
import AccountService from "@/services/AccountService";
export default {
  layout: "account",
  name: "ordersPage",
  // middleware: "authenticated",
  middleware: "auth",

  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
      order: false,
    };
  },
  async mounted() {
    const idOrder = this.$route.params.id;
    // const orderHelper = new OrderHelper(this.$http, this.$store,this.$i18n.locale);
    // // const order = await orderHelper.getOrderById(idOrder);
    // const order = await orderHelper.getOrderById(idOrder);

    const accountService = new AccountService({
      auth: this.$auth,
      http: this.$http,
      store: this.$store,
    });

    this.order = await accountService.getOrder({
      idOrder,
      languageIsoCode: this.$i18n.locale,
      currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
    });
  },
  computed: {},
  components: {
    AccountBody,
    Orders,
    TopMobileBack,
    OrderDetailsContent,
    OrderCard,
    ShoppingBagIcon,
  },
};
