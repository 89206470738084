
import BackToLogin from "./components/BackToLogin.vue";
import PasswordLayout from "./components/PasswordLayout.vue";
import SentReset from "./components/SentReset.vue";
export default {
  layout: "MainLayout",
  name: "ValidatPasswordPage",
  // middleware: ["authenticated"],
  auth: false,
  components: {
    PasswordLayout,
    SentReset,
    BackToLogin,
  },
};
