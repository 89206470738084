
import { Drawer, Button } from 'element-ui';
import CartItems from '@/pages/cart/components/CartItems.vue';
import MiniCartSlider from './MiniCartSlider.vue';
import FavoriesItems from '@/pages/account/components/FavoriesItems.vue';
import cartMixin from '@/mixins/cartMixin.js';
import favoriteMixin from '@/mixins/favoriteMixin.js';

export default {
  mixins: [cartMixin, favoriteMixin],
  data() {
    return {
      drawer: true,
      direction: 'rtl',
      active: 1,
    };
  },
  computed: {
    cartItem() {
      return this.$store.state.shop.cart.listItemLocal;
    },
    toggleMenu: {
      get() {
        const a = this.$store.state.shared.menu.tabActive;
        this.active = a;
        return this.$store.state.shared.menu.menuState;
      },
      set(val) {
        this.$store.commit('shared/menu/toggle', {
          tabActive: 2,
          open: val,
        });
      },
    },
    title() {
      switch (this.active) {
        case 1:
          return this.$t('My favorites');
        case 2:
          return this.$t('Products in my cart');
        case 3:
          return 'Mon compte';
      }
    },

    currencySign() {
      return this.$localisation.currencySign;
    },

    productsFav() {
      const list = this.$store.state.shop.favorite.FavoriteDetail;
      if (list) {
        return list.map((item) => {
          return item.product;
        });
      }
      return [];
    },

    //new

    totalDiscount() {
      return this.cart?.Total?.Discount?.TaxIncl;
    },
    totalToPay() {
      return this.cart?.Total?.ToPay?.TaxIncl;
    },
  },

  methods: {
    openFavorite() {
      this.active = 1;
      this.favoriteService.fetchFavorite();
    },
    openCart() {
      this.active = 2;
      this.cartService.fetchCartSummary();
    },
    handleClose() {
      // this.$confirm("Are you sure you want to close this?")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});

      this.$store.commit('shared/menu/toggle', {
        tabActive: 2,
        open: false,
      });
    },
    hideMiniCart() {
      this.$store.commit('shared/menu/toggle', {
        tabActive: 2,
        open: false,
      });
    },
    onAccountClick() {
      if (this.$auth.loggedIn) {
        this.$store.commit('shared/menu/toggle', {
          tabActive: 1,
          open: false,
        });
        this.$router.push(this.localePath('/account/informations'));
      } else {
        this.active = 3;
      }
    },

    lastCat() {
      try {
        const LAST_CATEGORY_VISITED = 'LAST_CATEGORY_VISITED';
        const lastCat = this.$cookies.get(LAST_CATEGORY_VISITED);

        if (lastCat) {
          return lastCat;
        }
      } catch (error) {}
      return this.localePath('/');
    },
    continueMyShopping() {
      this.$router.push({
        path: this.lastCat(),
      });
      this.hideMiniCart();
    },
  },
  components: {
    Drawer,
    Button,
    CartItems,
    MiniCartSlider,
    FavoriesItems,
  },
};
