
import DefaultLayout from './DefaultLayout.vue';
import sharedMixin from '@/mixins/sharedMixin.js';

export default {
  mixins: [sharedMixin],
  computed: {
    menuIsSticky() {
      return this.$store.state.shared.menu.sticky;
    },
  },
  mounted() {},
  components: { DefaultLayout },
};
