
import { UserIcon, ChevronLeftIcon } from "vue-feather-icons";
import Address from "@/pages/account/components/Address.vue";
import AccountBody from "@/pages/account/components/AccountBody.vue";
import Orders from "@/pages/account/components/Orders.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
export default {
  layout: "account",
  name: "accountPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: this.$t("My addresses"),
        icon: "",
      },
    };
  },
  components: {
    UserIcon,
    ChevronLeftIcon,
    Address,
    AccountBody,
    Orders,
    TopMobileBack,
  },
  mounted() {},
};
