
import { InfoIcon } from 'vue-feather-icons';
import { LoyaltyService } from '@/helpers/LoyaltyService';
import { Progress } from 'element-ui';

export default {
  props: {
    idLoyaltyProgram: {
      type: Number,
    },
    tierActive: {
      type: String,
    },
    totleCumulatePoints: {
      type: Number,
      default: 0,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tiers: [],
    };
  },
  computed: {
    pointsToReach() {
      let r = null;
      this.tiers.map((tier, index) => {
        if (
          this.totleCumulatePoints >= tier.LoyaltyProgramTierFromTotalPoint &&
          this.totleCumulatePoints <= tier.LoyaltyProgramTierToTotalPoint
        ) {
          if (this.tiers.length - 1 > index) {
            r = this.tiers[index + 1];
          }
        }
      });

      return r;
    },
    nextTier() {
      const points = this.customer?.TotalCumulatePoints || 0;

      const currentTierIndex =
        this.tiers?.findIndex((item) => {
          return (
            points >= (item?.LoyaltyProgramTierFromTotalPoint || 0) &&
            points < (item?.LoyaltyProgramTierToTotalPoint || 0)
          );
        }) || 0;


      if (this.tiers) {
        if ((currentTierIndex || 0) < this.tiers?.length - 1) {
          return this.tiers[currentTierIndex + 1];
        }
      }

      return null;
    },

    progersse() {
      if (this.nextTier) {
        return (
          ((this.customer?.TotalPoints || 0) /
            (this.nextTier?.LoyaltyProgramTierFromTotalPoint || 0)) *
          100
        );
      }
      return 100;
    },
  },
  methods: {
    isActive(tierName) {
      if (this.tierActive) {
        return this.tierActive.toLowerCase() === tierName.toLowerCase();
      }

      return false;
    },
    loadata() {
      if (this.idLoyaltyProgram) {
        const loyaltyService = new LoyaltyService(this.$http);
        loyaltyService
          .loyaltyTier({
            LanguageIsoCode: this.$i18n.locale,
            IdLoyaltyProgram: this.idLoyaltyProgram,
          })
          .then((data) => {
            this.tiers = data.LoyaltyProgramTiers;
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
  },

  mounted() {
    this.loadata();
  },

  components: { InfoIcon, Progress },
};
