
import AccountBody from '@/pages/account/components/AccountBody.vue';
import TopMobileBack from '@/pages/account/components/TopMobileBack.vue';
import RegisterFormInfo from '@/pages/authenticate/components/RegisterFormInfo.vue';
import { ReferralService } from '@/services/ReferralService';

import {
  UsersIcon,
  InfoIcon,
  ChevronLeftIcon,
  CopyIcon,
} from 'vue-feather-icons';
export default {
  layout: 'account',
  name: 'accountReferralsPage',
  middleware: 'auth',

  data() {
    return {
      sponsorData: null,
      referralService: new ReferralService({ http: this.$http }),
      invited: null,
      stateSelected: 'all', //all , pending, ready,used
    };
  },
  methods: {
    getCode() {
      this.referralService
        .getCode({ languageIsoCode: this.$i18n.locale })
        .then((data) => {
          this.sponsorData = data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getInvitations() {
      this.referralService
        .getInvitations({ languageIsoCode: this.$i18n.locale })
        .then((data) => {
          this.invited = data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    copyPromoCode(text) {
      if (process.client) {
        navigator.clipboard.writeText(text);
        this.$message.info({
          message: text + ' - ' + this.$t('Copied'),
          duration: 1000,
          showClose: true,
        });
      }
    },

    getState(sponsor) {
      // if (sponsor?.State?.Label === "pending") {
      //   return { value: "pending", label: this.$t("invitation sent") };
      // }
      // if (sponsor?.State?.Label === "complete") {
      //   if (sponsor?.Promocode?.State?.Expired) {
      //     return { value: "expired", label: this.$t("order completed") };
      //   }
      //   if (sponsor?.Promocode?.State?.Used) {
      //     return { value: "used", label: this.$t("order completed") };
      //   } else {
      //     return { value: "ready", label: this.$t("guest registered") };
      //   }
      // }
      if (sponsor?.State?.Label === 'pending') {
        return { value: 'pending', label: this.$t('invitation sent') };
      }
      if (sponsor?.Promocode?.State?.Expired) {
        return { value: 'expired', label: this.$t('guest registered') };
      }
      if (sponsor?.Promocode?.State?.Used) {
        return { value: 'used', label: this.$t('guest registered') };
      } else {
        return { value: 'ready', label: this.$t('guest registered') };
      }
    },

    getReduction(sponsor) {
      const reduction = sponsor?.Promocode?.Reduction?.Value?.TaxIncl || 0;
      if (this.getState(sponsor).value === 'pending') {
        return `${reduction}${this.currencySign} ${this.$t('waiting')}`;
      } else {
        return `${reduction}${this.currencySign} ${this.$t('won')}`;
      }
    },
  },
  async mounted() {
    this.getCode();
    this.getInvitations();
  },
  computed: {
    currencySign() {
      return this.$localisation.currencySign;
    },
    sponsored() {
      if (this.stateSelected === 'all') {
        return this.invited?.Sponsored;
      }
      return this.invited?.Sponsored?.filter((item) => {
        let isOk = false;
        if (this.stateSelected === 'pending') {
          if (item?.State?.Label === 'pending') {
            isOk = true;
          }
        }
        if (this.stateSelected === 'used') {
          if (item?.State?.Label === 'complete') {
            if (item?.Promocode?.State?.Used) {
              isOk = true;
            }
          }
        }
        if (this.stateSelected === 'ready') {
          if (item?.State?.Label === 'complete') {
            if (!item?.Promocode?.State?.Used) {
              isOk = true;
            }
          }
        }
        return isOk;
      });
    },
  },
  components: {
    AccountBody,
    TopMobileBack,
    UsersIcon,
    ChevronLeftIcon,
    RegisterFormInfo,
    CopyIcon,
    InfoIcon,
  },
};
