
import { Progress } from 'element-ui';
import { PackageIcon, CreditCardIcon } from 'vue-feather-icons';
import { Tunnel } from '@/helpers/Tunnel.js';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
  },
  components: {
    Progress,
    PackageIcon,
    CreditCardIcon,
  },
  computed: {
    deliveryFreeFrom() {
      return this.$store.state.shop.settings.deliveryFreeFrom;
    },
    deliveryFreeFromPrice() {
      const price = this.deliveryFreeFrom.SettingValue;

      return price;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },

    getTotale() {
      const tunnel = new Tunnel(this, this.$store);
      return tunnel.totalCart.ttc;
    },

    restOfFees() {
      return this.deliveryFreeFromPrice.ht - Number(this.getTotale);
    },
  },
};
