
import Teleport from 'vue2-teleport';
import { Button, Popover, Drawer } from 'element-ui';
import { ClockIcon, ChevronDownIcon, MapPinIcon } from 'vue-feather-icons';
import RelaiPointCard from './RelaiPointCard.vue';
import CarriersHoraire from '../pages/cart/components/CarriersHoraire.vue';
import carriersMixin from '@/mixins/carriersMixin.js';
import cartMixin from '@/mixins/cartMixin.js';
import addressMixin from '@/mixins/addressMixin.js';
import { TunnelHelper } from '@/helpers/TunnelHelper.js';
export default {
  mixins: [carriersMixin, cartMixin, addressMixin],
  props: {
    carrier: {
      type: Object,
      required: true,
    },
    carrierType: {
      type: String,
    },
  },
  data() {
    return {
      drawer: false,
      drawerTitle: '',
      direction: 'rtl',
      relayPointSelected: null,
      mapSelected: '',
      pointsClickAndCollectMap: [],
      relayPoints: [],
    };
  },
  computed: {
    getCarrier() {
      return this.cart?.Shipping?.Carrier;
    },
  },
  async mounted() {
    // if (this.carrier?.Addresses) {
    //   this.relayPointSelected = this.carrier?.Addresses[0];
    // }
    // if (this.carrier?.IdCarrier === this.getCarrier?.IdCarrier) {
    //   await this.setCarrier();
    // }

    // if (this.carrier?.IdCarrier === this.getCarrier?.IdCarrier) {
    //   await this.setCarrier();
    // }

    if (this.carrierType === 'RelayPoint') {
      const tunnelHelper = new TunnelHelper(this.$http, this.$auth);
      try {
        const data = await tunnelHelper.getRelayPointsByIdAddressAndIdCarrier({
          idAddress: this.idAddressDelivery,
          idCarrier: this.carrier.IdCarrier,
        });

        this.relayPoints = data.RelayPoints;

        if (this.relayPoints) {
          this.relayPointSelected = this.relayPoints[0];
        }

        this.setCarrier();
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    selectRelayPoint() {
      this.drawer = true;
      this.mapSelected = 'CarriersMap';
      this.drawerTitle = ' Choisir un relais colis';
    },
    selectRelaitPoint($event) {
      this.relayPointSelected = $event;
      this.setCarrier();
      this.drawer = false;
    },
    async setCarrier() {
      const params = {
        idCarrier: this.carrier.IdCarrier,
      };
      if (this.relayPointSelected) {
        params.idRelayPoint = this.relayPointSelected.Id;
      }

      try {
        await this.cartService.setCarrierToCart(params);
        this.cartService.fetchCart();
      } catch (error) {
        console.log('error', error);
      }
    },
    isSelected(carrier) {
      if (this.getCarrier) {
        return carrier.IdCarrier === this.getCarrier.IdCarrier;
      }

      return false;
    },
    openMap(carrier) {
      this.pointsClickAndCollectMap = [
        {
          id: 0,
          latitude: carrier.StoreAttributes.Lat,
          longitude: carrier.StoreAttributes.Long,
        },
      ];
      this.drawer = true;
      this.drawerTitle = carrier.CarrierName + ' - ' + carrier.CarrierTitle;
      this.mapSelected = 'MAP';
    },
  },
  components: {
    Button,
    Popover,
    Drawer,
    RelaiPointCard,
    ClockIcon,
    ChevronDownIcon,
    MapPinIcon,
    CarriersHoraire,
    Teleport,
  },
};
