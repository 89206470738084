
import { Collapse, CollapseItem } from 'element-ui';
export default {
  props: {
    isCollapse: {
      required: true,
      Type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('HELP'),
          url: '/content/faq',
          childs: [
            {
              title: this.$t('Frequently Asked Questions'),
              url: '/content/faq',
            },
            {
              title: this.$t('Delivery & Returns'),
              url: this.$t('faq_livraisons_url') /*"/content/faq/deliveries"*/,
            },
            {
              title: this.$t('My account'),
              url: this.$auth.loggedIn
                ? '/account/informations'
                : '/authenticate/login',
            },
            {
              title: this.$t('Track my order'),
              url: '/account/orders',
            },
            {
              title: this.$t('Contact-us'),
              url: { name: 'contact-us' },
            },
          ],
        },
        {
          title: this.$t('ABOUT'),
          url: '/content/cgv',
          childs: [
            {
              title: this.$t('CGV'),
              url: '/content/cgv',
            },
            {
              title: this.$t('Legal Notice'),
              url: '/content/legal-notice',
            },
            {
              title: this.$t('Privacy'),
              url: '/content/privacy-policy',
            },
          ],
        },
        {
          title: this.$t('The brand'),
          url: '/marque',
          childs: [
            {
              title: this.$t('Manifesto'),
              url: '/marque',
            },
            {
              title: this.$t('Magazine'),
              url: '/blogs',
            },
            {
              title: this.$t('Lookbooks'),
              url: '/lookbooks',
            },
            {
              title: this.$t('Loyalty program'),
              url: `/${this.$t('loyalty_path')}`,
            },
            {
              title: this.$t('Refer a friend'),
              url: `/${this.$t('refer_path')}`,
            },
          ],
        },
        {
          title: this.$t('Our shops'),
          url: '/shops',
          childs: [
            {
              title: this.$t('89 Rivoli street, 75001 Paris'),
              url: `/${this.$t('shop_url')}/paris-rivoli`,
            },
            {
              title: this.$t('95 Saint-Lazare street, 75009 Paris'),
              url: `/${this.$t('shop_url')}/paris-st-lazare`,
            },
            {
              title: this.$t('92 Alesia street, 75 014 Paris'),
              url: `/${this.$t('shop_url')}/paris-alesia`,
            },
            {
              title: this.$t('57 Poland Street, 78100 Saint Germain-en-Laye'),
              url: `/${this.$t('shop_url')}/st-german-en-laye`,
            },
            {
              title: this.$t('2 Jean Roisin street, 59 800 Lille'),
              url: `/${this.$t('shop_url')}/lille`,
            },
          ],
        },
      ];
    },
  },
  components: {
    Collapse,
    CollapseItem,
  },
};
