
import { ChevronDownIcon, AwardIcon } from 'vue-feather-icons';
import { Button, Progress, Dialog } from 'element-ui';
import LoyaltyPosts from '@/pages/account/components/LoyaltyPosts.vue';
import LoyaltyRewards from '@/pages/account/components/LoyaltyRewards.vue';
import LoyaltyTop from '@/pages/account/components/LoyaltyTop.vue';
import LoyaltyNotSubscirbe from '@/pages/account/components/LoyaltyNotSubscirbe.vue';
import LoyaltyOurStatusItems from '@/pages/account/components/LoyaltyOurStatusItems.vue';
import TopMobileBack from '@/pages/account/components/TopMobileBack.vue';
import LoyaltyProgress from '@/pages/account/components/LoyaltyProgress.vue';
import AccountBody from '@/pages/account/components/AccountBody.vue';

export default {
  name: 'accountLoyalty',
  layout: 'account',
  middleware: 'auth',
  data() {
    return {};
  },
  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },

    listRewards() {
      return this.$store.state.shop.loyalty.listRewards;
    },
    listMyRewards() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewards;
    },
    myRewardsState() {
      return this.$store.state.shop.loyalty.myRewards.LoyaltyRewardState;
    },
  },
  mounted() {
    this.$store.dispatch('shop/loyalty/fetchMyRewards', {
      loyaltyRewardState: 'all',
    });
    this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer', {
      onSuccess: () => {
        this.gotToPageHash();
      },
    });
    this.$store.dispatch('shop/loyalty/fetchRewards');
  },
  watch: {
    $route() {
      if (this.$route.hash) {
        this.gotToPageHash();
      }
    },
  },
  methods: {
    scrollTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 60,
        behavior: 'smooth',
      });
    },
    gotToPageHash() {
      const hash = this.$route.hash;
      if (hash) {
        setTimeout(() => {
          const ref = this.$refs[hash];
          if (ref) {
            this.scrollTo(hash);
          }
        }, 500);
      }
    },
  },
  components: {
    LoyaltyPosts,
    LoyaltyTop,
    ChevronDownIcon,
    Progress,
    Button,
    LoyaltyNotSubscirbe,
    LoyaltyRewards,
    Dialog,
    LoyaltyOurStatusItems,
    TopMobileBack,
    AccountBody,
    AwardIcon,
    LoyaltyProgress,
  },
};
