import { render, staticRenderFns } from "./CarrierCard.vue?vue&type=template&id=71a755f5"
import script from "./CarrierCard.vue?vue&type=script&lang=js"
export * from "./CarrierCard.vue?vue&type=script&lang=js"
import style0 from "./CarrierCard.vue?vue&type=style&index=0&id=71a755f5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarriersMap: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/CarriersMap.vue').default,Map: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/Map.vue').default})
