
import {
  CheckCircleIcon,
  MessageSquareIcon,
  StarIcon,
} from 'vue-feather-icons';
import AccountBody from '@/pages/account/components/AccountBody.vue';
import TopMobileBack from '@/pages/account/components/TopMobileBack.vue';
import AccountContentCard from '@/pages/account/components/AccountContentCard.vue';
import { CustumerHelpers } from '@/helpers/CustumerHelpers';
import MessageBubble from '@/pages/account/components/MessageBubble.vue';
import { Button } from 'element-ui';
import { convertTextToLink } from '@/utils/text';

export default {
  layout: 'account',
  name: 'ticketCustomerAccountPage',
  // middleware: "authenticated",
  middleware: 'auth',
  data() {
    return {
      custumerHelpers: new CustumerHelpers(this.$http, this.$auth),
      tickets: [],
      ticketsInProgress: [],
      ticketsClosed: [],
      showInProgress: true,
      convertTextToLink
    };
  },
  computed: {
    getTitcketsInProgress() {
      return this.ticketsInProgress;
    },
    getTitcketsClosed() {
      return this.ticketsClosed;
    },
    ticketsShowed() {
      if (this.showInProgress) {
        return this.getTitcketsInProgress;
      } else {
        return this.getTitcketsClosed;
      }
    },
  },
  methods: {
    getTitckets() {
      this.custumerHelpers
        .listTickets()
        .then((res) => {
          this.tickets = res;
          this.tickets.map((item) => {
            if (item.Status === 'Closed') {
              this.ticketsClosed.push(item);
            } else {
              this.ticketsInProgress.push(item);
            }
          });
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    getImage(product) {
      try {
        return product?.Media?.Images?.product_large[0].Src;
      } catch (error) {
        return require(`~/assets/img/default-img.jpg`);
      }
    },
    rateTicket(event, ticket) {
      this.custumerHelpers
        .rateTicket({
          IdTicket: ticket.IdTicket,
          Rating: event.value,
        })
        .then((res) => {
          if (res.message) {
            ticket.TicketRating = event.value;
            this.$notify({
              title: this.$t('Success'),
              message: this.$t(res.message),
              type: 'success',
            });
          }
        })
        .catch((error) => {
          if (error.response.data) {
            this.$notify.error({
              title: this.$t('Error'),
              message: this.$t(error.response.data.message),
            });
          }
        });
    },
  },
  mounted() {
    this.getTitckets();
  },
  components: {
    AccountBody,
    TopMobileBack,
    MessageSquareIcon,
    AccountContentCard,
    StarIcon,
    MessageBubble,
    CheckCircleIcon,
    Button,
  },
};
