import { render, staticRenderFns } from "./BlockSearch.vue?vue&type=template&id=e0d76ed4"
import script from "./BlockSearch.vue?vue&type=script&lang=js"
export * from "./BlockSearch.vue?vue&type=script&lang=js"
import style0 from "./BlockSearch.vue?vue&type=style&index=0&id=e0d76ed4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandLogo: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/BrandLogo.vue').default,ProductCardDefaultMultiple: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/ProductCard/ProductCardDefaultMultiple.vue').default})
