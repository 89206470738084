
import AccountBody from "@/pages/account/components/AccountBody.vue";
import TopMobileBack from "@/pages/account/components/TopMobileBack.vue";
import ReturnCard from "@/pages/account/components/ReturnCard.vue";
import { OrderHelper } from "/helpers/OrderHelper";
import { ShoppingBagIcon, ChevronLeftIcon } from "vue-feather-icons";
import OrderProductCard from "@/pages/account/components/OrderProductCard.vue";
import { Button } from "element-ui";
export default {
  layout: "account",
  name: "returnConfirmedPage",
  // middleware: "authenticated",
  middleware: "auth",
  data() {
    return {
      tab: "",
      TopMobileBackInfo: {
        title: "Mon compte",
        icon: "",
      },
      feedback: false,
    };
  },
  async mounted() {
    const idOrderReturn = this.$route.params.id;
    const orderHelper = new OrderHelper(
      this.$http,
      this.$store,
      this.$i18n.locale
    );
    const feedback = await orderHelper.getReturnDetail({
      idOrderReturn,
      languageIsoCode: this.$i18n.locale,
    });

    this.feedback = feedback;
  },
  computed: {},
  components: {
    AccountBody,
    TopMobileBack,
    ReturnCard,
    ShoppingBagIcon,
    ChevronLeftIcon,
    OrderProductCard,
    Button,
  },
};
