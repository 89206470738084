
import { Checkbox } from 'element-ui';
import { ShopHelper } from '@/helpers/ShopHelper';
export default {
  data() {
    return {
      emailSub: false,
      smsSub: false,
      loading: false,
    };
  },
  methods: {
    emailSubscripe() {
      const shopHelper = new ShopHelper(this.$http, this);
      const email = this.$auth?.user?.Customer?.Email;
      if (email) {
        this.loading = true;
        if (this.emailSub) {
          shopHelper
            .newsletterSubscription({
              email,
              languageIsoCode: this.$i18n.locale,
              currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
            })
            .then((data) => {})
            .catch((error) => {
              this.emailSub = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          shopHelper
            .newsletterUnSubscription({
              email,
            })
            .then((data) => {})
            .catch((error) => {
              this.emailSub = true;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  components: {
    Checkbox,
  },
};
