
import { Button, Collapse, CollapseItem } from 'element-ui';
import { XIcon } from 'vue-feather-icons';
import cartMixin from '@/mixins/cartMixin.js';

export default {
  mixins: [cartMixin],
  props: {
    hasProduct: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: 'Continue',
    },

    hideBtn: {
      type: Boolean,
      default: false,
    },
    hideTotal: {
      type: Boolean,
      default: false,
    },
    mobileFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    commade() {
      this.$emit('onCommandeClick');
    },
    removeCodePromo(idPromoCode) {
      this.cartService
        .removeCodePromo({
          idPromoCode,
          idCart: this.cart.IdCart,
        })
        .then(async (data) => {
          this.cartService.fetchCart();
        })
        .catch((err) => {});
    },
  },
  mounted() {},
  computed: {
    cartTotal() {
      return this.cart?.Total?.TaxIncl;
    },
    productCount() {
      return this.cart?.Total?.Product?.Quantity;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    ///new
    totalProduct() {
      return this.cart?.Total?.Product?.TaxIncl;
    },
    totalShipping() {
      return Number(this.cart?.Total?.Shipping?.TaxIncl);
    },
    totalDiscount() {
      const discount = this.cart?.Total?.Discount?.TaxIncl;
      return discount || 0;
    },
    totalToPay() {
      return this.cart?.Total?.ToPay?.TaxIncl;
    },
    promoCodes() {
      return this.cart?.Discounts?.PromoCodes;
    },
  },
  components: {
    Button,
    Collapse,
    CollapseItem,
    XIcon,
  },
};
