
import { ClockIcon, ChevronDownIcon, MapPinIcon } from 'vue-feather-icons';
import { Popover } from 'element-ui';
import CarriersHoraire from '@/pages/cart/components/CarriersHoraire.vue';
import cartMixin from '@/mixins/cartMixin.js';

export default {
  mixins: [cartMixin],
  props: {
    modifyLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // listCarriersHome: [],
      // listCarriersClickAndCollect: [],
      // listCarriersRelayPoint: [],
    };
  },
  computed: {
    carrier() {
      return this.cart?.Shipping?.Carrier;
    },

    relayPointSelected() {
      return null;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    deliveryFees() {
      const deliveryFeesTaxIncl = this.cart.Total.Shipping.TaxIncl;
      return deliveryFeesTaxIncl;
      //return 0;
    },
  },
  components: {
    ClockIcon,
    ChevronDownIcon,
    MapPinIcon,
    Popover,
    CarriersHoraire,
  },
};
