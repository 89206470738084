
import { SearchIcon } from 'vue-feather-icons';

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
};

export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Please type your address',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {},
    };
  },
  methods: {
    textChange($event) {
      const val = $event.target.value;
      this.$emit('onTextChange', { text: val });
    },

    setPlace(place) {
      console.log('place',place);
      
      this.getAddressData(this.formatResult(place));
    },

    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val =
            place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      returnData['formatted_address'] = place.formatted_address;
      returnData['vicinity'] = place.vicinity;
      returnData['name'] = place.name;
      return returnData;
    },

    getAddressData(addressData) {
      this.address = addressData;
      console.log('addressData',addressData);
      
      const text = this.$refs.addressInput.$el.value;
      const street_number = addressData.street_number
        ? `${addressData.street_number}, `
        : '';
      const route = addressData.route || '';
      const city = addressData.locality;
      const postalCode = addressData.postal_code;
      const country = addressData.country;

      const res = {
        text,
        street_number,
        route,
        city,
        postalCode,
        country,
        courtAddress: `${street_number}${route}` || this.address.name,
        formatted_address: this.address.formatted_address,
      };

      this.$emit('onAddressSelected', res);
    },
  },
  components: {
    SearchIcon,
  },
};
