import { render, staticRenderFns } from "./MenuMobile.vue?vue&type=template&id=3ddd945d"
import script from "./MenuMobile.vue?vue&type=script&lang=js"
export * from "./MenuMobile.vue?vue&type=script&lang=js"
import style0 from "./MenuMobile.vue?vue&type=style&index=0&id=3ddd945d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubMenu: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/SubMenu.vue').default,SubCategoryMark: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/SubCategoryMark.vue').default})
