
import { ChevronDownIcon } from 'vue-feather-icons';
import OrderDetailsContent from './OrderDetailsContent.vue';
export default {
  props: {
    title: {
      type: String,
    },
    state: {
      type: Object,
      //{text,value}
    },
    linkItemDown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listIsOpen: false,
    };
  },
  computed: {},
  mounted() {},
  components: {
    ChevronDownIcon,
    OrderDetailsContent,
  },
};
