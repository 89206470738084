
import { Button } from 'element-ui';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';

export default {
  props: {
    categoryLink: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      productHelpers: new ProductHelpers(this.$http, this, this.$i18n.locale),
      categorySeo: [],
      ourSelectionProducts: [],
    };
  },
  computed: {},
  methods: {
    getCategory() {
      try {
        this.productHelpers
          .categoryProducts({
            permalink: this.categoryLink,
            offset: 0,
            limit: 8,
            languageIsoCode: this.$i18n.locale,
            currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          })
          .then((data) => {
            if (data?.Products) {
              this.ourSelectionProducts = data?.Products;
            }
          });
      } catch (error) {}
    },
  },
  async mounted() {
    this.getCategory();
  },
  components: {
    Button,
  },
};
