
import { Checkbox } from "element-ui";
import ReturnForm from "@/pages/account/components/ReturnForm.vue";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    isReturn: {
      type: Boolean,
      default: false,
    },
    confirmed: {
      type: Boolean,
      default: false,
    },
    returnDetail: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showReturnForm: false,
    };
  },
  computed: {
    productImage() {
      if (this.product?.Media?.Images?.product_small?.length) {
        return this.product?.Media?.Images?.product_small[0]?.Src;
      }

      return "";
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    totalProduct() {
      return (
        Number(this.product?.Quantity) *
        Number(this.product?.Price?.Paid?.TaxIncl)
      );
    },
  },
  methods: {
    getSize(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Size"
        );
        return size;
      } catch (error) {}
      return {};
    },
    getColor(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Color"
        );
        return size;
      } catch (error) {}
      return {};
    },
    onChecked() {
      
      this.$emit("onChecked", {
        product: this.product,
        returnForm: this.$refs.returnForm,
        checked: this.showReturnForm,
      });
    },
    getOrderReturnDetailTypeLocation(OrderReturnDetailType) {
      const index = OrderReturnDetailType.findIndex(
        (item) => item.LanguageIsoCode === this.$i18n.locale
      );
      if (index !== -1) {
        return OrderReturnDetailType[index];
      }
      return OrderReturnDetailType[0];
    },
    getOrderOrderReturnDetailState(OrderReturnDetailState) {
      const index = OrderReturnDetailState.findIndex(
        (item) => item.LanguageIsoCode === this.$i18n.locale
      );
      if (index !== -1) {
        return OrderReturnDetailState[index];
      }
      return OrderReturnDetailState[0];
    },
  },
  components: {
    Checkbox,
    ReturnForm,
  },
};
