
import GoogeWalletButton from '@/pages/account/components/GoogeWalletButton.vue';
import LoyaltyTier from '@/pages/account/components/LoyaltyTier.vue';
import { Button, Progress, Drawer } from 'element-ui';
export default {
  props: {
    customer: {
      type: Object,
    },
  },
  data() {
    return {
      showed: false,
    };
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
    circleOptions() {
      if (this.getIsMobile) {
        return {
          width: 290,
          strokeWidth: 36,
        };
      } else {
        return {
          width: 449,
          strokeWidth: 56,
        };
      }
    },
  },
  methods: {
    scrollTo() {},
  },

  mounted() {},

  components: {
    GoogeWalletButton,
    Button,
    Progress,
    Drawer,
    LoyaltyTier,
  },
};
